@import url('https://fonts.googleapis.com/css?family=Antic|Raleway&display=swap');

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  text-align: center;
}

/* Global Styles */
body {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: ivory;
  background-image: url('./images/tree.jpg');
  background-size: cover;
}

h1,
h2,
h3 {
  font-family: 'Antic', sans-serif;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 1.75rem;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: #cfc913;
}

p {
  font-size: 1.5rem;
  margin: 1rem auto;
  line-height: 1.75rem;
}

ul {
  border: 1px solid black;
  font-style: italic;
  border-radius: 3px;
  margin: 1rem;
}

#page-container {
  min-height: 110vh;
}

#content-wrap {
  padding-bottom: 2.5rem;
}

/* Header and Footer Styles */

header,
footer {
  background: #212427;
  color: ivory;
}

header {
  padding: 1rem;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

header nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

header nav a {
  margin-left: 1rem;
  font-size: 1.5rem;
  &:hover {
    font-style: italic;
    color: white;
  }
  @media (max-width: 500px) {
    font-size: 1rem;
  }
}

.active {
  font-weight: bold;
  color: #f7f5af;
}

footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
  margin-bottom: none;
  margin-top: 5rem;
  p {
    font-size: 1.3rem;
  }
  @media (max-width: 1000px) {
    p {
      font-size: 1.25rem;
    }
  }
  @media (max-width: 500px) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1rem;
    p {
      font-size: 1rem;
    }
  }
}
footer div {
  text-align: right;
  margin-right: 1rem;
}
footer div a {
  margin-left: 1rem;
  font-size: 2rem;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}
footer div a:hover {
  color: white;
  transform: scale(1.2);
}

.img-container {
  width: 5rem;
}

.img-container img {
  max-width: 100%;
}

.image-container {
  margin: 1rem auto;
  width: 45%;
  @media (max-width: 1000px) {
    width: 60%;
  }
}

.image-container img {
  max-width: 100%;
}

/* Home Styles */

.home-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  width: 50%;
  margin: 2rem auto 14rem auto;
  padding: 2rem;
  min-height: 80vh;
  @media (max-width: 500px) {
    width: 75%;
  }
}

.text-box {
  display: flex;
  flex-flow: column nowrap;
  justify-items: space-between;
  align-items: center;
  background: rgb(177, 189, 17);
  margin: 1rem;
  padding: 1rem;
  @media (max-width: 400px) {
    width: 90%;
    margin: auto;
    padding: 1.75rem;
  }
  h1 {
    margin-bottom: 1rem;
    @media (max-width: 1000px) {
      font-size: 2.5rem;
    }
  }
  p {
    @media (max-width: 500px) {
      font-size: 1rem;
    }
  }
}

button {
  background: #212427;
  color: ivory;
  padding: 1rem;
  border: none;
  margin: 1rem;
  font-family: 'Raleway', sans-serif;
  outline: none;
  transition: all 0.2s ease-in-out;
  font-size: 1.25rem;
  border-radius: 3px;
  @media (max-width: 400px) {
    font-size: 1rem;
    margin: 0.5rem;
    padding: 0.75rem;
  }
}

button:hover {
  transform: scale(1.2);
  font-style: italic;
}

/* About Page Styles */
.about a.school-link {
  color: black;
  font-weight: bold;
  &:hover {
    font-style: italic;
  }
}

.images-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    background: #457b2f;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.badge {
  max-width: 10em;
}

.resume-link {
  background: #212427;
  color: ivory;
  padding: 1rem;
  border: none;
  margin: 1rem;
  font-family: 'Raleway', sans-serif;
  outline: none;
  transition: all 0.2s ease-in-out;
  font-size: 1.25rem;
  &:hover {
    font-style: italic;
    transform: scale(1.2);
  }
}

/* Skills Styles */

.skills-container {
  width: 90%;
  background: rgba(255, 255, 255, 0.6);
  padding: 2rem 1rem 1rem 1rem;
  margin: 1rem;
}
.skills-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  width: 100%;
  margin: 1rem;
  @media (max-width: 800px) {
    margin-left: 0.1rem;
  }

  .skill-box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding: 0.5rem;
    padding-left: 0;
    @media (max-width: 800px) {
      width: 30%;
      padding: 0;
      margin: 0;
    }

    p {
      font-size: 1rem;
      margin: 0.5rem;
      @media (max-width: 800px) {
        font-size: 0.8rem;
      }
      &.fa {
        font-size: 2.75rem;
        color: black;
        margin-bottom: 0;
        @media (max-width: 800px) {
          font-size: 1rem;
        }
      }
    }
  }
}

/* Projects Page Styles */

.screenshot-container {
  width: 70%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin: 1rem;
  @media (max-width: 1000px) {
    width: 90%;
  }
}

img {
  max-width: 100%;
}

.projects-page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  width: 60%;
  margin: 1rem auto;
  padding: 2rem 1rem;
  @media (max-width: 1000px) {
    width: 80%;
  }
}

.projects-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  margin-bottom: 2rem;
  @media (max-width: 1000px) {
    margin-top: 3rem;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}

.project-box {
  background: rgba(255, 255, 255, 0.7);
  margin: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 45%;
  @media (max-width: 1500px) {
    width: 70%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
}

.modal {
  position: relative;
  top: -20%;
  right: 10%;
  transform: translate(25vw, 25vh);
  width: 70%;
  background: white;
  border-radius: 3px;
  padding: 1rem;
  @media (max-width: 1050px) {
    width: 85%;
    right: 17.5%;
  }
  @media (max-width: 400px) {
    width: 95%;
    right: 22.5%;
    padding: 0.25rem;
  }
}

.modal-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  h2 {
    margin: 1rem;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    @media (max-width: 1050px) {
      font-size: 2rem;
      margin: 0.8rem;
    }
  }
  h3 {
    font-size: 1.75rem;
    font-weight: bold;
    @media (max-width: 1050px) {
      font-size: 1.6rem;
    }
    @media (max-width: 1000px) {
      font-size: 1.5rem;
    }
    @media (max-width: 400px) {
      font-size: 1rem;
    }
  }
  p {
    margin: 1rem;
    line-height: 2rem;
    font-size: 1rem;
    @media (max-width: 1000px) {
      font-size: 0.75rem;
    }
    @media (max-width: 500px) {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
  a {
    margin: 1rem;
    font-size: 2rem;
    transition: all 0.2s ease-in-out;
    &:hover {
      font-style: italic;
      transform: scale(1.2);
    }
  }
  a.fa {
    font-size: 4rem;
    @media (max-width: 1050px) {
      font-size: 2.25rem;
    }
  }
  .links-box {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    @media (max-width: 1000px) {
      width: 80%;
    }
    @media (max-width: 500px) {
      flex-flow: row nowrap;
      margin-left: -1rem;
    }
    @media (max-width: 400px) {
      justify-content: center;
    }
    p {
      font-weight: bold;
      @media (max-width: 500px) {
        line-height: 1rem;
      }
      @media (max-width: 400px) {
        display: none;
      }
    }
    div {
      width: 50%;
      transition: all 0.2s ease-in-out;
      @media (max-width: 400px) {
        width: 25%;
      }
      &:hover {
        transform: scale(1.2);
      }
    }
    a:hover {
      color: #fc7138;
    }
  }
}

/* Contact Page Styles */
.contact-page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  width: 60%;
  margin: 1rem auto;
  padding: 3rem;
  min-height: 90vh;
  @media (max-width: 1000px) {
    width: 80%;
    padding: 1rem;
  }
  h2 {
    @media (max-width: 1000px) {
      font-size: 3rem;
      padding: 2rem;
    }
    @media (max-width: 1000px) {
      font-size: 2rem;
      padding: 1rem;
    }
  }
}

form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  margin: 2rem;
  width: 65%;
  @media (max-width: 1000px) {
    width: 95%;
  }
}

label {
  margin: 2rem 1rem;
  padding: 1rem;
  font-weight: bold;
  font-size: 1.2em;
}

input,
textarea {
  margin: 1rem;
  border: none;
  line-height: 2rem;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  @media (max-width: 1000px) {
    margin: 1rem 0 1rem 0;
  }
}

form button {
  width: 50%;
  margin: 1rem auto;
}

input[placeholder],
textarea[placeholder] {
  text-align: left;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1em;
}
